/* SASS variables and intellisense */
/*---------------------------------------------------------------------------
                              THE NAV BAR
---------------------------------------------------------------------------*/
/* Add a PINK background color with a little bit see-through */
.navbar {
  z-index: 20;
  margin-bottom: 0;
  line-height: 1.42857143 !important;
  border-radius: 0; }
  .theme-default .navbar {
    background-color: #0A4A7C;
    border-bottom: 1px solid #0A4A7C; }
  .theme-helsa .navbar {
    background-color: #FFFFFF;
    border-bottom: 1px solid #6CB680; }
  .theme-ptj .navbar {
    background-color: #00A0C8;
    border-bottom: 5px solid #ffffff; }
  .theme-rjl .navbar {
    background-color: #fff;
    border-bottom: 1px solid #579835; }
  .theme-achima .navbar {
    background-color: #AA2B23;
    border-bottom: 1px solid #0A4A7C; }
  .theme-blekinge .navbar {
    background-color: #fff;
    border-bottom: 1px solid #E5E5E5; }
  .theme-sll .navbar {
    background-color: #fff;
    border-bottom: 3px solid #00aeef; }
  .theme-halland .navbar {
    background-color: #fff;
    border-bottom: 3px solid #2F7CAC; }
  .theme-orebro .navbar {
    background-color: #fff;
    border-bottom: 2px solid #EEEEEE; }
  .theme-rg .navbar {
    background-color: #DCF1DA;
    border-bottom: 1px solid #cccccc; }

@media (min-width: 767px) {
  /* Add a WHITE color to all navbar links */
  .theme-default .navbar li a, .theme-default .navbar .navbar-brand {
    color: #fff !important; }
  .theme-helsa .navbar li a, .theme-helsa .navbar .navbar-brand {
    color: #000000 !important; }
  .theme-ptj .navbar li a, .theme-ptj .navbar .navbar-brand {
    color: #ffffff !important; }
  .theme-rjl .navbar li a, .theme-rjl .navbar .navbar-brand {
    color: #000 !important; }
  .theme-achima .navbar li a, .theme-achima .navbar .navbar-brand {
    color: #fff !important; }
  .theme-blekinge .navbar li a, .theme-blekinge .navbar .navbar-brand {
    color: #000 !important; }
  .theme-sll .navbar li a, .theme-sll .navbar .navbar-brand {
    color: #000 !important; }
  .theme-halland .navbar li a, .theme-halland .navbar .navbar-brand {
    color: #000 !important; }
  .theme-orebro .navbar li a, .theme-orebro .navbar .navbar-brand {
    color: #000 !important; }
  .theme-rg .navbar li a, .theme-rg .navbar .navbar-brand {
    color: #000 !important; } }

/*.navbar-brand{
    padding: 15px 0;
}*/
@media (max-width: 767px) {
  /* Add a WHITE color to all navbar links */
  .theme-default .navbar li a, .theme-default .navbar .navbar-brand {
    color: #0A4A7C !important; }
  .theme-helsa .navbar li a, .theme-helsa .navbar .navbar-brand {
    color: #000000 !important; }
  .theme-ptj .navbar li a, .theme-ptj .navbar .navbar-brand {
    color: #ffffff !important; }
  .theme-rjl .navbar li a, .theme-rjl .navbar .navbar-brand {
    color: #fff !important; }
  .theme-achima .navbar li a, .theme-achima .navbar .navbar-brand {
    color: #fff !important; }
  .theme-blekinge .navbar li a, .theme-blekinge .navbar .navbar-brand {
    color: #fff !important; }
  .theme-sll .navbar li a, .theme-sll .navbar .navbar-brand {
    color: #fff !important; }
  .theme-halland .navbar li a, .theme-halland .navbar .navbar-brand {
    color: #fff !important; }
  .theme-orebro .navbar li a, .theme-orebro .navbar .navbar-brand {
    color: #fff !important; }
  .theme-rg .navbar li a, .theme-rg .navbar .navbar-brand {
    color: #000 !important; } }

/* When hoovering over a menu, WHITE box and PINK text */
.theme-default .navbar-nav li a:hover, .theme-default .navbar-nav li.active a {
  color: #0A4A7C !important;
  background-color: #A0F0EB !important; }

.theme-helsa .navbar-nav li a:hover, .theme-helsa .navbar-nav li.active a {
  color: #6CB680 !important;
  background-color: transparent !important; }

.theme-ptj .navbar-nav li a:hover, .theme-ptj .navbar-nav li.active a {
  color: #ffffff !important;
  background-color: #E78300 !important; }

.theme-rjl .navbar-nav li a:hover, .theme-rjl .navbar-nav li.active a {
  color: #579835 !important;
  background-color: #bfd730 !important; }

.theme-achima .navbar-nav li a:hover, .theme-achima .navbar-nav li.active a {
  color: #AA2B23 !important;
  background-color: #fff !important; }

.theme-blekinge .navbar-nav li a:hover, .theme-blekinge .navbar-nav li.active a {
  color: #fff !important;
  background-color: #00A6E2 !important; }

.theme-sll .navbar-nav li a:hover, .theme-sll .navbar-nav li.active a {
  color: #fff !important;
  background-color: #00aeef !important; }

.theme-halland .navbar-nav li a:hover, .theme-halland .navbar-nav li.active a {
  color: #fff !important;
  background-color: #6DACDE !important; }

.theme-orebro .navbar-nav li a:hover, .theme-orebro .navbar-nav li.active a {
  color: #fff !important;
  background-color: #4F80FF !important; }

.theme-rg .navbar-nav li a:hover, .theme-rg .navbar-nav li.active a {
  color: #fff !important;
  background-color: #96d491 !important; }

/* Remove border color from the collapsible button */
.navbar-default .navbar-toggle {
  border-color: transparent; }
  .theme-default .navbar-default .navbar-toggle {
    color: #fff !important; }
  .theme-helsa .navbar-default .navbar-toggle {
    color: #000000 !important; }
  .theme-ptj .navbar-default .navbar-toggle {
    color: #ffffff !important; }
  .theme-rjl .navbar-default .navbar-toggle {
    color: #000 !important; }
  .theme-achima .navbar-default .navbar-toggle {
    color: #fff !important; }
  .theme-blekinge .navbar-default .navbar-toggle {
    color: #000 !important; }
  .theme-sll .navbar-default .navbar-toggle {
    color: #000 !important; }
  .theme-halland .navbar-default .navbar-toggle {
    color: #000 !important; }
  .theme-orebro .navbar-default .navbar-toggle {
    color: #000 !important; }
  .theme-rg .navbar-default .navbar-toggle {
    color: #000 !important; }

/*---------------------------------------------------------------------------
                              HAMBURGER MENU
---------------------------------------------------------------------------*/
/*Hamburger-meny before and after click - color on the button*/
.navbar-toggle {
  background-color: transparent !important; }

/*Hamburger-meny before and after click - color on the stripes/bars*/
.theme-default .navbar-toggle .icon-bar {
  background-color: #fff !important; }

.theme-helsa .navbar-toggle .icon-bar {
  background-color: #000000 !important; }

.theme-ptj .navbar-toggle .icon-bar {
  background-color: #ffffff !important; }

.theme-rjl .navbar-toggle .icon-bar {
  background-color: #000 !important; }

.theme-achima .navbar-toggle .icon-bar {
  background-color: #fff !important; }

.theme-blekinge .navbar-toggle .icon-bar {
  background-color: #000 !important; }

.theme-sll .navbar-toggle .icon-bar {
  background-color: #000 !important; }

.theme-halland .navbar-toggle .icon-bar {
  background-color: #000 !important; }

.theme-orebro .navbar-toggle .icon-bar {
  background-color: #000 !important; }

.theme-rg .navbar-toggle .icon-bar {
  background-color: #000 !important; }

/*Hamburger-meny hoover - color on the button*/
.navbar-toggle:hover {
  background-color: transparent !important; }

/*Hamburger-meny hoover - color on the stripes/bars*/
.theme-default .navbar-toggle:hover .icon-bar {
  background-color: #A0F0EB !important; }

.theme-helsa .navbar-toggle:hover .icon-bar {
  background-color: transparent !important; }

.theme-ptj .navbar-toggle:hover .icon-bar {
  background-color: #E78300 !important; }

.theme-rjl .navbar-toggle:hover .icon-bar {
  background-color: #bfd730 !important; }

.theme-achima .navbar-toggle:hover .icon-bar {
  background-color: #fff !important; }

.theme-blekinge .navbar-toggle:hover .icon-bar {
  background-color: #00A6E2 !important; }

.theme-sll .navbar-toggle:hover .icon-bar {
  background-color: #00aeef !important; }

.theme-halland .navbar-toggle:hover .icon-bar {
  background-color: #6DACDE !important; }

.theme-orebro .navbar-toggle:hover .icon-bar {
  background-color: #4F80FF !important; }

.theme-rg .navbar-toggle:hover .icon-bar {
  background-color: #96d491 !important; }

@media (max-width: 767px) {
  .navbar-nav {
    margin-top: 0px;
    margin-bottom: 0px; } }

/*---------------------------------------------------------------------------
                      DROP DOWN FROM HAMBURGER COLOR 
---------------------------------------------------------------------------*/
@media (max-width: 767px) {
  /* Changing the color of the big part */
  .theme-default .navbar li {
    background-color: #A0F0EB !important; }
  .theme-helsa .navbar li {
    background-color: #E7E7E8 !important; }
  .theme-ptj .navbar li {
    background-color: #0075B0 !important; }
  .theme-rjl .navbar li {
    background-color: #bfd730 !important; }
  .theme-achima .navbar li {
    background-color: #AA2B23 !important; }
  .theme-blekinge .navbar li {
    background-color: #00A6E2 !important; }
  .theme-sll .navbar li {
    background-color: #00aeef !important; }
  .theme-halland .navbar li {
    background-color: #6DACDE !important; }
  .theme-orebro .navbar li {
    background-color: #4F80FF !important; }
  .theme-rg .navbar li {
    background-color: #fff !important; }
  /* When hoovering over a menu, WHITE box and BLUE text */
  .theme-default .navbar-nav li a:hover, .theme-default .navbar-nav li.active a {
    color: #A0F0EB !important;
    background-color: #0A4A7C !important; }
  .theme-helsa .navbar-nav li a:hover, .theme-helsa .navbar-nav li.active a {
    color: #E7E7E8 !important;
    background-color: #000000 !important; }
  .theme-ptj .navbar-nav li a:hover, .theme-ptj .navbar-nav li.active a {
    color: #0075B0 !important;
    background-color: #ffffff !important; }
  .theme-rjl .navbar-nav li a:hover, .theme-rjl .navbar-nav li.active a {
    color: #bfd730 !important;
    background-color: #fff !important; }
  .theme-achima .navbar-nav li a:hover, .theme-achima .navbar-nav li.active a {
    color: #AA2B23 !important;
    background-color: #fff !important; }
  .theme-blekinge .navbar-nav li a:hover, .theme-blekinge .navbar-nav li.active a {
    color: #00A6E2 !important;
    background-color: #fff !important; }
  .theme-sll .navbar-nav li a:hover, .theme-sll .navbar-nav li.active a {
    color: #00aeef !important;
    background-color: #fff !important; }
  .theme-halland .navbar-nav li a:hover, .theme-halland .navbar-nav li.active a {
    color: #6DACDE !important;
    background-color: #fff !important; }
  .theme-orebro .navbar-nav li a:hover, .theme-orebro .navbar-nav li.active a {
    color: #4F80FF !important;
    background-color: #fff !important; }
  .theme-rg .navbar-nav li a:hover, .theme-rg .navbar-nav li.active a {
    color: #fff !important;
    background-color: #000 !important; }
  /* Changing the color of the lines + the FONT*/
  #myNavbar {
    font-weight: normal !important; }
    .theme-default #myNavbar {
      background-color: #A0F0EB !important;
      border-color: #A0F0EB !important; }
    .theme-helsa #myNavbar {
      background-color: #E7E7E8 !important;
      border-color: #E7E7E8 !important; }
    .theme-ptj #myNavbar {
      background-color: #0075B0 !important;
      border-color: #0075B0 !important; }
    .theme-rjl #myNavbar {
      background-color: #bfd730 !important;
      border-color: #bfd730 !important; }
    .theme-achima #myNavbar {
      background-color: #AA2B23 !important;
      border-color: #AA2B23 !important; }
    .theme-blekinge #myNavbar {
      background-color: #00A6E2 !important;
      border-color: #00A6E2 !important; }
    .theme-sll #myNavbar {
      background-color: #00aeef !important;
      border-color: #00aeef !important; }
    .theme-halland #myNavbar {
      background-color: #6DACDE !important;
      border-color: #6DACDE !important; }
    .theme-orebro #myNavbar {
      background-color: #4F80FF !important;
      border-color: #4F80FF !important; }
    .theme-rg #myNavbar {
      background-color: #fff !important;
      border-color: #fff !important; } }

/*---------------------------------------------------------------------------
                              DROP SHADOW UNDER NAVBAR 
---------------------------------------------------------------------------*/
.navbar .navbar-header .navbar-brand .navbarLogo, .navbarLogo, .container .row .navbar-brand.patient-logo .navbarLogo {
  display: inline-block; }
  .theme-default .navbar .navbar-header .navbar-brand .navbarLogo, .theme-default .navbarLogo, .theme-default .container .row .navbar-brand.patient-logo .navbarLogo {
    height: 24px;
    margin-top: -4px; }
  .theme-helsa .navbar .navbar-header .navbar-brand .navbarLogo, .theme-helsa .navbarLogo, .theme-helsa .container .row .navbar-brand.patient-logo .navbarLogo {
    height: 48px;
    margin-top: -15px; }
  .theme-ptj .navbar .navbar-header .navbar-brand .navbarLogo, .theme-ptj .navbarLogo, .theme-ptj .container .row .navbar-brand.patient-logo .navbarLogo {
    height: 36px;
    margin-top: -8px; }
  .theme-rjl .navbar .navbar-header .navbar-brand .navbarLogo, .theme-rjl .navbarLogo, .theme-rjl .container .row .navbar-brand.patient-logo .navbarLogo {
    height: 38px;
    margin-top: -10px; }
  .theme-achima .navbar .navbar-header .navbar-brand .navbarLogo, .theme-achima .navbarLogo, .theme-achima .container .row .navbar-brand.patient-logo .navbarLogo {
    height: 24px;
    margin-top: -4px; }
  .theme-blekinge .navbar .navbar-header .navbar-brand .navbarLogo, .theme-blekinge .navbarLogo, .theme-blekinge .container .row .navbar-brand.patient-logo .navbarLogo {
    height: 32px;
    margin-top: -6px; }
  .theme-sll .navbar .navbar-header .navbar-brand .navbarLogo, .theme-sll .navbarLogo, .theme-sll .container .row .navbar-brand.patient-logo .navbarLogo {
    height: 32px;
    margin-top: -6px; }
  .theme-halland .navbar .navbar-header .navbar-brand .navbarLogo, .theme-halland .navbarLogo, .theme-halland .container .row .navbar-brand.patient-logo .navbarLogo {
    height: 32px;
    margin-top: -6px; }
  .theme-orebro .navbar .navbar-header .navbar-brand .navbarLogo, .theme-orebro .navbarLogo, .theme-orebro .container .row .navbar-brand.patient-logo .navbarLogo {
    height: 32px;
    margin-top: -6px; }
  .theme-rg .navbar .navbar-header .navbar-brand .navbarLogo, .theme-rg .navbarLogo, .theme-rg .container .row .navbar-brand.patient-logo .navbarLogo {
    height: 32px;
    margin-top: -6px; }

.patient-logo {
  margin-top: 10px; }

.theme-default .lattakutenText {
  display: none;
  font-size: 24px;
  font-weight: 600; }

.theme-helsa .lattakutenText {
  display: none;
  font-size: 24px;
  font-weight: 700; }

.theme-ptj .lattakutenText {
  display: none;
  font-size: 24px;
  font-weight: 600; }

.theme-rjl .lattakutenText {
  display: none;
  font-size: 24px;
  font-weight: 600; }

.theme-achima .lattakutenText {
  display: none;
  font-size: 24px;
  font-weight: 600; }

.theme-blekinge .lattakutenText {
  display: none;
  font-size: 24px;
  font-weight: 600; }

.theme-sll .lattakutenText {
  display: none;
  font-size: 24px;
  font-weight: 600; }

.theme-halland .lattakutenText {
  display: none;
  font-size: 24px;
  font-weight: 600; }

.theme-orebro .lattakutenText {
  display: none;
  font-size: 24px;
  font-weight: 600; }

.theme-rg .lattakutenText {
  display: none;
  font-size: 24px;
  font-weight: 600; }

/*---------------------------------------------------------------------------
                               SECOND HEADER 
---------------------------------------------------------------------------*/
.secondHeader-rightElement, .secondHeader-leftElement {
  margin-top: -28px; }

.section-head {
  margin-bottom: 24px; }

.secondHeader {
  z-index: 11;
  top: 50px;
  position: fixed;
  left: 0px;
  right: 0px;
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 8px;
  margin: 0 auto; }
  .theme-default .secondHeader {
    background-color: #F1F1F1;
    border-bottom: 1px solid #CCC; }
  .theme-helsa .secondHeader {
    background-color: #D7EAD9;
    border-bottom: 1px solid #6CB680; }
  .theme-ptj .secondHeader {
    background-color: #0075B0;
    border-bottom: 1px solid #ffffff; }
  .theme-rjl .secondHeader {
    background-color: #579835;
    border-bottom: 1px solid #579835; }
  .theme-achima .secondHeader {
    background-color: #EDEDED;
    border-bottom: 1px solid #EDEDED; }
  .theme-blekinge .secondHeader {
    background-color: #E5F6FC;
    border-bottom: 1px solid #E5F6FC; }
  .theme-sll .secondHeader {
    background-color: #00aeef;
    border-bottom: 1px solid #00aeef; }
  .theme-halland .secondHeader {
    background-color: #D1E6F5;
    border-bottom: 1px solid #6DACDE; }
  .theme-orebro .secondHeader {
    background-color: #004F9F;
    border-bottom: 1px solid #0b1d32; }
  .theme-rg .secondHeader {
    background-color: #F6F6F6;
    border-bottom: 0px solid #cccccc; }

.secondHeader h1, .secondHeader h2, .secondHeader h3, .secondHeader h4, .secondHeader h5, .secondHeader h6 {
  text-align: center; }
  .theme-default .secondHeader h1, .theme-default .secondHeader h2, .theme-default .secondHeader h3, .theme-default .secondHeader h4, .theme-default .secondHeader h5, .theme-default .secondHeader h6 {
    color: #0A4A7C; }
  .theme-helsa .secondHeader h1, .theme-helsa .secondHeader h2, .theme-helsa .secondHeader h3, .theme-helsa .secondHeader h4, .theme-helsa .secondHeader h5, .theme-helsa .secondHeader h6 {
    color: #1D5E74; }
  .theme-ptj .secondHeader h1, .theme-ptj .secondHeader h2, .theme-ptj .secondHeader h3, .theme-ptj .secondHeader h4, .theme-ptj .secondHeader h5, .theme-ptj .secondHeader h6 {
    color: #ffffff; }
  .theme-rjl .secondHeader h1, .theme-rjl .secondHeader h2, .theme-rjl .secondHeader h3, .theme-rjl .secondHeader h4, .theme-rjl .secondHeader h5, .theme-rjl .secondHeader h6 {
    color: #fff; }
  .theme-achima .secondHeader h1, .theme-achima .secondHeader h2, .theme-achima .secondHeader h3, .theme-achima .secondHeader h4, .theme-achima .secondHeader h5, .theme-achima .secondHeader h6 {
    color: #880000; }
  .theme-blekinge .secondHeader h1, .theme-blekinge .secondHeader h2, .theme-blekinge .secondHeader h3, .theme-blekinge .secondHeader h4, .theme-blekinge .secondHeader h5, .theme-blekinge .secondHeader h6 {
    color: #000; }
  .theme-sll .secondHeader h1, .theme-sll .secondHeader h2, .theme-sll .secondHeader h3, .theme-sll .secondHeader h4, .theme-sll .secondHeader h5, .theme-sll .secondHeader h6 {
    color: #ffffff; }
  .theme-halland .secondHeader h1, .theme-halland .secondHeader h2, .theme-halland .secondHeader h3, .theme-halland .secondHeader h4, .theme-halland .secondHeader h5, .theme-halland .secondHeader h6 {
    color: #000; }
  .theme-orebro .secondHeader h1, .theme-orebro .secondHeader h2, .theme-orebro .secondHeader h3, .theme-orebro .secondHeader h4, .theme-orebro .secondHeader h5, .theme-orebro .secondHeader h6 {
    color: #ffffff; }
  .theme-rg .secondHeader h1, .theme-rg .secondHeader h2, .theme-rg .secondHeader h3, .theme-rg .secondHeader h4, .theme-rg .secondHeader h5, .theme-rg .secondHeader h6 {
    color: #000; }

.theme-default .secondHeader p, .theme-default .secondHeader span, .theme-default .secondHeader label {
  color: #000; }

.theme-helsa .secondHeader p, .theme-helsa .secondHeader span, .theme-helsa .secondHeader label {
  color: #000000; }

.theme-ptj .secondHeader p, .theme-ptj .secondHeader span, .theme-ptj .secondHeader label {
  color: #ffffff; }

.theme-rjl .secondHeader p, .theme-rjl .secondHeader span, .theme-rjl .secondHeader label {
  color: #fff; }

.theme-achima .secondHeader p, .theme-achima .secondHeader span, .theme-achima .secondHeader label {
  color: #000; }

.theme-blekinge .secondHeader p, .theme-blekinge .secondHeader span, .theme-blekinge .secondHeader label {
  color: #000; }

.theme-sll .secondHeader p, .theme-sll .secondHeader span, .theme-sll .secondHeader label {
  color: #ffffff; }

.theme-halland .secondHeader p, .theme-halland .secondHeader span, .theme-halland .secondHeader label {
  color: #000; }

.theme-orebro .secondHeader p, .theme-orebro .secondHeader span, .theme-orebro .secondHeader label {
  color: #ffffff; }

.theme-rg .secondHeader p, .theme-rg .secondHeader span, .theme-rg .secondHeader label {
  color: #000; }

@media screen and (max-width: 768px) {
  .secondHeader-patientInfo {
    text-align: right; }
  .patientcontact .secondHeader-patientInfo {
    text-align: center; } }

@media screen and (min-width: 768px) {
  .secondHeader-patientInfo, .patientcontact .secondHeader-patientInfo {
    text-align: center; } }

.theme-default .languageHeader {
  background-color: #D0F8F5;
  border-bottom: 1px solid #A0F0EB; }

.theme-helsa .languageHeader {
  background-color: #D7EAD9;
  border-bottom: 1px solid #BEDCC1; }

.theme-ptj .languageHeader {
  background-color: #F3F3F3;
  border-bottom: 5px solid #CCCCCC; }

.theme-rjl .languageHeader {
  background-color: #EDEDED;
  border-bottom: 1px solid #EDEDED; }

.theme-achima .languageHeader {
  background-color: #EDEDED;
  border-bottom: 1px solid #EDEDED; }

.theme-blekinge .languageHeader {
  background-color: #E5F6FC;
  border-bottom: 1px solid #E5F6FC; }

.theme-sll .languageHeader {
  background-color: #FFFFFF;
  border-bottom: 3px solid #FFFFFF; }

.theme-halland .languageHeader {
  background-color: #D3E6F5;
  border-bottom: 3px solid #6DACDE; }

.theme-orebro .languageHeader {
  background-color: #E3F2F8;
  border-bottom: 2px solid #C8E1EB; }

.theme-rg .languageHeader {
  background-color: #F6F6F6;
  border-bottom: 1px solid #ccc; }

.languageHeaderBottomMargin {
  margin-bottom: 36px; }

.languageHeader {
  position: absolute;
  width: 100%;
  left: 0;
  margin-top: -30px; }

@media screen and (max-width: 768px) {
  .languageHeader {
    margin-top: -15px; } }
