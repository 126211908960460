/* SASS variables and intellisense */
/*---------------------------------------------------------------------------
                                 FONTS 
---------------------------------------------------------------------------*/
/*------------------------------  Font sizes ----------------------------*/
.theme-default p {
  font-family: "proximanova-regular, sans-serif";
  /* font-weight: 400; */
  color: #000000; }

.theme-helsa p {
  font-family: "source-sans-regular, sans-serif";
  font-weight: 400;
  color: #000000; }

.theme-ptj p {
  font-family: "source-sans-pro-r, sans-serif";
  font-weight: 400;
  color: #333333; }

.theme-rjl p {
  font-family: "open-sans-regular, sans-serif";
  font-weight: 400;
  color: #000; }

.theme-achima p {
  font-family: "lato-regular, sans-serif";
  font-weight: 400;
  color: #000; }

.theme-blekinge p {
  font-family: "open-sans-regular, sans-serif";
  font-weight: 400;
  color: #000; }

.theme-sll p {
  font-family: "open-sans-regular, sans-serif";
  font-weight: 400;
  color: #000; }

.theme-halland p {
  font-family: "open-sans-regular, sans-serif";
  font-weight: 400;
  color: #000; }

.theme-orebro p {
  font-family: "arial-regular, sans-serif";
  font-weight: 400;
  color: #000; }

.theme-rg p {
  font-family: "museo-sans, sans-serif";
  font-weight: 500;
  color: #000; }

.theme-default .modal .modal-content p, .theme-default .modal .modal-content span {
  color: #000000; }

.theme-helsa .modal .modal-content p, .theme-helsa .modal .modal-content span {
  color: #000000; }

.theme-ptj .modal .modal-content p, .theme-ptj .modal .modal-content span {
  color: #333333; }

.theme-rjl .modal .modal-content p, .theme-rjl .modal .modal-content span {
  color: #000; }

.theme-achima .modal .modal-content p, .theme-achima .modal .modal-content span {
  color: #000; }

.theme-blekinge .modal .modal-content p, .theme-blekinge .modal .modal-content span {
  color: #000; }

.theme-sll .modal .modal-content p, .theme-sll .modal .modal-content span {
  color: #000; }

.theme-halland .modal .modal-content p, .theme-halland .modal .modal-content span {
  color: #000; }

.theme-orebro .modal .modal-content p, .theme-orebro .modal .modal-content span {
  color: #000; }

.theme-rg .modal .modal-content p, .theme-rg .modal .modal-content span {
  color: #000; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0; }
  .theme-default h1, .theme-default h2, .theme-default h3, .theme-default h4, .theme-default h5, .theme-default h6 {
    font-family: "proximanova-bold, sans-serif";
    font-weight: 700;
    color: #0A4A7C; }
  .theme-helsa h1, .theme-helsa h2, .theme-helsa h3, .theme-helsa h4, .theme-helsa h5, .theme-helsa h6 {
    font-family: "source-sans-bold, sans-serif";
    font-weight: 700;
    color: #1D5E74; }
  .theme-ptj h1, .theme-ptj h2, .theme-ptj h3, .theme-ptj h4, .theme-ptj h5, .theme-ptj h6 {
    font-family: "source-sans-pro-sb, sans-serif";
    font-weight: 700;
    color: #333333; }
  .theme-rjl h1, .theme-rjl h2, .theme-rjl h3, .theme-rjl h4, .theme-rjl h5, .theme-rjl h6 {
    font-family: "open-sans-semibold, sans-serif";
    font-weight: 600;
    color: #000; }
  .theme-achima h1, .theme-achima h2, .theme-achima h3, .theme-achima h4, .theme-achima h5, .theme-achima h6 {
    font-family: "lato-bold, sans-serif";
    font-weight: 700;
    color: #880000; }
  .theme-blekinge h1, .theme-blekinge h2, .theme-blekinge h3, .theme-blekinge h4, .theme-blekinge h5, .theme-blekinge h6 {
    font-family: "open-sans-semibold, sans-serif";
    font-weight: 700;
    color: #000; }
  .theme-sll h1, .theme-sll h2, .theme-sll h3, .theme-sll h4, .theme-sll h5, .theme-sll h6 {
    font-family: "open-sans-semibold, sans-serif";
    font-weight: 700;
    color: #000; }
  .theme-halland h1, .theme-halland h2, .theme-halland h3, .theme-halland h4, .theme-halland h5, .theme-halland h6 {
    font-family: "open-sans-semibold, sans-serif";
    font-weight: 700;
    color: #000; }
  .theme-orebro h1, .theme-orebro h2, .theme-orebro h3, .theme-orebro h4, .theme-orebro h5, .theme-orebro h6 {
    font-family: "arial-bold, sans-serif";
    font-weight: 700;
    color: #000; }
  .theme-rg h1, .theme-rg h2, .theme-rg h3, .theme-rg h4, .theme-rg h5, .theme-rg h6 {
    font-family: "museo-sans, sans-serif";
    font-weight: 700;
    color: #000; }

.theme-default .modal .modal-content h1 span, .theme-default
.modal .modal-content h2 span, .theme-default
.modal .modal-content h3 span, .theme-default
.modal .modal-content h4 span, .theme-default
.modal .modal-content h5 span, .theme-default
.modal .modal-content h6 span {
  color: #0A4A7C; }

.theme-helsa .modal .modal-content h1 span, .theme-helsa
.modal .modal-content h2 span, .theme-helsa
.modal .modal-content h3 span, .theme-helsa
.modal .modal-content h4 span, .theme-helsa
.modal .modal-content h5 span, .theme-helsa
.modal .modal-content h6 span {
  color: #1D5E74; }

.theme-ptj .modal .modal-content h1 span, .theme-ptj
.modal .modal-content h2 span, .theme-ptj
.modal .modal-content h3 span, .theme-ptj
.modal .modal-content h4 span, .theme-ptj
.modal .modal-content h5 span, .theme-ptj
.modal .modal-content h6 span {
  color: #333333; }

.theme-rjl .modal .modal-content h1 span, .theme-rjl
.modal .modal-content h2 span, .theme-rjl
.modal .modal-content h3 span, .theme-rjl
.modal .modal-content h4 span, .theme-rjl
.modal .modal-content h5 span, .theme-rjl
.modal .modal-content h6 span {
  color: #000; }

.theme-achima .modal .modal-content h1 span, .theme-achima
.modal .modal-content h2 span, .theme-achima
.modal .modal-content h3 span, .theme-achima
.modal .modal-content h4 span, .theme-achima
.modal .modal-content h5 span, .theme-achima
.modal .modal-content h6 span {
  color: #880000; }

.theme-blekinge .modal .modal-content h1 span, .theme-blekinge
.modal .modal-content h2 span, .theme-blekinge
.modal .modal-content h3 span, .theme-blekinge
.modal .modal-content h4 span, .theme-blekinge
.modal .modal-content h5 span, .theme-blekinge
.modal .modal-content h6 span {
  color: #000; }

.theme-sll .modal .modal-content h1 span, .theme-sll
.modal .modal-content h2 span, .theme-sll
.modal .modal-content h3 span, .theme-sll
.modal .modal-content h4 span, .theme-sll
.modal .modal-content h5 span, .theme-sll
.modal .modal-content h6 span {
  color: #000; }

.theme-halland .modal .modal-content h1 span, .theme-halland
.modal .modal-content h2 span, .theme-halland
.modal .modal-content h3 span, .theme-halland
.modal .modal-content h4 span, .theme-halland
.modal .modal-content h5 span, .theme-halland
.modal .modal-content h6 span {
  color: #000; }

.theme-orebro .modal .modal-content h1 span, .theme-orebro
.modal .modal-content h2 span, .theme-orebro
.modal .modal-content h3 span, .theme-orebro
.modal .modal-content h4 span, .theme-orebro
.modal .modal-content h5 span, .theme-orebro
.modal .modal-content h6 span {
  color: #000; }

.theme-rg .modal .modal-content h1 span, .theme-rg
.modal .modal-content h2 span, .theme-rg
.modal .modal-content h3 span, .theme-rg
.modal .modal-content h4 span, .theme-rg
.modal .modal-content h5 span, .theme-rg
.modal .modal-content h6 span {
  color: #000; }

/* SMALL screens sizes */
@media screen and (min-width: 1200px) {
  .waitingRoomSection h1 {
    font-size: 72px; }
  .waitingRoomSection h2 {
    font-size: 36px; }
  .theme-default .waitingRoomSection blockquote {
    font-size: 28px; }
  .theme-helsa .waitingRoomSection blockquote {
    font-size: 28px; }
  .theme-ptj .waitingRoomSection blockquote {
    font-size: 28px; }
  .theme-rjl .waitingRoomSection blockquote {
    font-size: 28px; }
  .theme-achima .waitingRoomSection blockquote {
    font-size: 28px; }
  .theme-blekinge .waitingRoomSection blockquote {
    font-size: 28px; }
  .theme-sll .waitingRoomSection blockquote {
    font-size: 28px; }
  .theme-halland .waitingRoomSection blockquote {
    font-size: 28px; }
  .theme-orebro .waitingRoomSection blockquote {
    font-size: 28px; }
  .theme-rg .waitingRoomSection blockquote {
    font-size: 28px; }
  .waitingRoomSection .btn.btn-primary.bigBtn {
    font-size: 28px; } }

.theme-default .modal .modal-content h1, .theme-default
.modal .modal-content h2, .theme-default
.modal .modal-content h3, .theme-default
.modal .modal-content h4, .theme-default
.modal .modal-content h5, .theme-default
.modal .modal-content h6 {
  color: #0A4A7C; }

.theme-helsa .modal .modal-content h1, .theme-helsa
.modal .modal-content h2, .theme-helsa
.modal .modal-content h3, .theme-helsa
.modal .modal-content h4, .theme-helsa
.modal .modal-content h5, .theme-helsa
.modal .modal-content h6 {
  color: #1D5E74; }

.theme-ptj .modal .modal-content h1, .theme-ptj
.modal .modal-content h2, .theme-ptj
.modal .modal-content h3, .theme-ptj
.modal .modal-content h4, .theme-ptj
.modal .modal-content h5, .theme-ptj
.modal .modal-content h6 {
  color: #333333; }

.theme-rjl .modal .modal-content h1, .theme-rjl
.modal .modal-content h2, .theme-rjl
.modal .modal-content h3, .theme-rjl
.modal .modal-content h4, .theme-rjl
.modal .modal-content h5, .theme-rjl
.modal .modal-content h6 {
  color: #000; }

.theme-achima .modal .modal-content h1, .theme-achima
.modal .modal-content h2, .theme-achima
.modal .modal-content h3, .theme-achima
.modal .modal-content h4, .theme-achima
.modal .modal-content h5, .theme-achima
.modal .modal-content h6 {
  color: #880000; }

.theme-blekinge .modal .modal-content h1, .theme-blekinge
.modal .modal-content h2, .theme-blekinge
.modal .modal-content h3, .theme-blekinge
.modal .modal-content h4, .theme-blekinge
.modal .modal-content h5, .theme-blekinge
.modal .modal-content h6 {
  color: #000; }

.theme-sll .modal .modal-content h1, .theme-sll
.modal .modal-content h2, .theme-sll
.modal .modal-content h3, .theme-sll
.modal .modal-content h4, .theme-sll
.modal .modal-content h5, .theme-sll
.modal .modal-content h6 {
  color: #000; }

.theme-halland .modal .modal-content h1, .theme-halland
.modal .modal-content h2, .theme-halland
.modal .modal-content h3, .theme-halland
.modal .modal-content h4, .theme-halland
.modal .modal-content h5, .theme-halland
.modal .modal-content h6 {
  color: #000; }

.theme-orebro .modal .modal-content h1, .theme-orebro
.modal .modal-content h2, .theme-orebro
.modal .modal-content h3, .theme-orebro
.modal .modal-content h4, .theme-orebro
.modal .modal-content h5, .theme-orebro
.modal .modal-content h6 {
  color: #000; }

.theme-rg .modal .modal-content h1, .theme-rg
.modal .modal-content h2, .theme-rg
.modal .modal-content h3, .theme-rg
.modal .modal-content h4, .theme-rg
.modal .modal-content h5, .theme-rg
.modal .modal-content h6 {
  color: #000; }

.theme-default .groupTitle .HeaddingDoctorView {
  font-size: 14px; }

.theme-helsa .groupTitle .HeaddingDoctorView {
  font-size: 14px; }

.theme-ptj .groupTitle .HeaddingDoctorView {
  font-size: 14px; }

.theme-rjl .groupTitle .HeaddingDoctorView {
  font-size: 14px; }

.theme-achima .groupTitle .HeaddingDoctorView {
  font-size: 14px; }

.theme-blekinge .groupTitle .HeaddingDoctorView {
  font-size: 14px; }

.theme-sll .groupTitle .HeaddingDoctorView {
  font-size: 14px; }

.theme-halland .groupTitle .HeaddingDoctorView {
  font-size: 14px; }

.theme-orebro .groupTitle .HeaddingDoctorView {
  font-size: 14px; }

.theme-rg .groupTitle .HeaddingDoctorView {
  font-size: 14px; }

/* SMALL screens sizes */
@media screen and (max-width: 768px) {
  .theme-default h1 {
    font-size: 24px; }
  .theme-helsa h1 {
    font-size: 24px; }
  .theme-ptj h1 {
    font-size: 24px; }
  .theme-rjl h1 {
    font-size: 24px; }
  .theme-achima h1 {
    font-size: 24px; }
  .theme-blekinge h1 {
    font-size: 24px; }
  .theme-sll h1 {
    font-size: 24px; }
  .theme-halland h1 {
    font-size: 24px; }
  .theme-orebro h1 {
    font-size: 24px; }
  .theme-rg h1 {
    font-size: 24px; }
  .theme-default h2 {
    font-size: 22px; }
  .theme-helsa h2 {
    font-size: 22px; }
  .theme-ptj h2 {
    font-size: 22px; }
  .theme-rjl h2 {
    font-size: 22px; }
  .theme-achima h2 {
    font-size: 22px; }
  .theme-blekinge h2 {
    font-size: 22px; }
  .theme-sll h2 {
    font-size: 22px; }
  .theme-halland h2 {
    font-size: 22px; }
  .theme-orebro h2 {
    font-size: 22px; }
  .theme-rg h2 {
    font-size: 22px; }
  .theme-default h3 {
    font-size: 20px; }
  .theme-helsa h3 {
    font-size: 20px; }
  .theme-ptj h3 {
    font-size: 20px; }
  .theme-rjl h3 {
    font-size: 20px; }
  .theme-achima h3 {
    font-size: 20px; }
  .theme-blekinge h3 {
    font-size: 20px; }
  .theme-sll h3 {
    font-size: 20px; }
  .theme-halland h3 {
    font-size: 20px; }
  .theme-orebro h3 {
    font-size: 20px; }
  .theme-rg h3 {
    font-size: 20px; }
  .theme-default h4 {
    font-size: 18px; }
  .theme-helsa h4 {
    font-size: 18px; }
  .theme-ptj h4 {
    font-size: 18px; }
  .theme-rjl h4 {
    font-size: 18px; }
  .theme-achima h4 {
    font-size: 18px; }
  .theme-blekinge h4 {
    font-size: 18px; }
  .theme-sll h4 {
    font-size: 18px; }
  .theme-halland h4 {
    font-size: 18px; }
  .theme-orebro h4 {
    font-size: 18px; }
  .theme-rg h4 {
    font-size: 18px; } }

/* Bigger screens sizes */
@media screen and (min-width: 768px) {
  .groupTitle .HeaddingDoctorView {
    margin-right: 80px; } }

/* Bigger screens sizes */
@media screen and (min-width: 768px) {
  .theme-default .bigInfoForPatient {
    font-size: 28px; }
  .theme-helsa .bigInfoForPatient {
    font-size: 28px; }
  .theme-ptj .bigInfoForPatient {
    font-size: 28px; }
  .theme-rjl .bigInfoForPatient {
    font-size: 28px; }
  .theme-achima .bigInfoForPatient {
    font-size: 28px; }
  .theme-blekinge .bigInfoForPatient {
    font-size: 28px; }
  .theme-sll .bigInfoForPatient {
    font-size: 28px; }
  .theme-halland .bigInfoForPatient {
    font-size: 28px; }
  .theme-orebro .bigInfoForPatient {
    font-size: 28px; }
  .theme-rg .bigInfoForPatient {
    font-size: 28px; } }

.extremelyImportant {
  font-weight: bold; }
  .theme-default .extremelyImportant {
    font-size: 148px; }
  .theme-helsa .extremelyImportant {
    font-size: 148px; }
  .theme-ptj .extremelyImportant {
    font-size: 148px; }
  .theme-rjl .extremelyImportant {
    font-size: 148px; }
  .theme-achima .extremelyImportant {
    font-size: 148px; }
  .theme-blekinge .extremelyImportant {
    font-size: 148px; }
  .theme-sll .extremelyImportant {
    font-size: 148px; }
  .theme-halland .extremelyImportant {
    font-size: 148px; }
  .theme-orebro .extremelyImportant {
    font-size: 148px; }
  .theme-rg .extremelyImportant {
    font-size: 148px; }

@media screen and (max-width: 768px) {
  .paragraphHeadding {
    font-size: 18px;
    font-weight: bold; } }

/*------------------------------  Text color ----------------------------*/
.theme-default a {
  font-family: "proximanova-regular, sans-serif";
  font-weight: 400;
  color: #0A4A7C; }

.theme-helsa a {
  font-family: "source-sans-regular, sans-serif";
  font-weight: 400;
  color: #6CB680; }

.theme-ptj a {
  font-family: "source-sans-pro-r, sans-serif";
  font-weight: 400;
  color: #00A0C8; }

.theme-rjl a {
  font-family: "open-sans-regular, sans-serif";
  font-weight: 400;
  color: #0093d0; }

.theme-achima a {
  font-family: "lato-regular, sans-serif";
  font-weight: 400;
  color: #00ADA8; }

.theme-blekinge a {
  font-family: "open-sans-regular, sans-serif";
  font-weight: 400;
  color: #00A6E2; }

.theme-sll a {
  font-family: "open-sans-regular, sans-serif";
  font-weight: 400;
  color: #034ea2; }

.theme-halland a {
  font-family: "open-sans-regular, sans-serif";
  font-weight: 400;
  color: #6DACDE; }

.theme-orebro a {
  font-family: "arial-regular, sans-serif";
  font-weight: 400;
  color: #1b579b; }

.theme-rg a {
  font-family: "museo-sans, sans-serif";
  font-weight: 500;
  color: #0097cf; }

a:hover {
  cursor: pointer; }
  .theme-default a:hover {
    color: #0A4A7C; }
  .theme-helsa a:hover {
    color: #6CB680; }
  .theme-ptj a:hover {
    color: #0075B0; }
  .theme-rjl a:hover {
    color: #0063a2; }
  .theme-achima a:hover {
    color: #00ADA8; }
  .theme-blekinge a:hover {
    color: #00A6E2; }
  .theme-sll a:hover {
    color: #034ea2; }
  .theme-halland a:hover {
    color: #2F7CAC; }
  .theme-orebro a:hover {
    color: #1b579b; }
  .theme-rg a:hover {
    color: #26BDF5; }

.theme-default blockquote {
  font-family: "proximanova-regular, sans-serif";
  font-weight: 400;
  color: #000000;
  font-size: 16px;
  padding: 10px 20px;
  margin: 0 0 20px;
  border-left: 5px solid #0A4A7C; }

.theme-helsa blockquote {
  font-family: "source-sans-regular, sans-serif";
  font-weight: 400;
  color: #000000;
  font-size: 16px;
  padding: 10px 20px;
  margin: 0 0 20px;
  border-left: 5px solid #6CB680; }

.theme-ptj blockquote {
  font-family: "source-sans-pro-r, sans-serif";
  font-weight: 400;
  color: #333333;
  font-size: 16px;
  padding: 10px 20px;
  margin: 0 0 20px;
  border-left: 5px solid #00A0C8; }

.theme-rjl blockquote {
  font-family: "open-sans-regular, sans-serif";
  font-weight: 400;
  color: #000;
  font-size: 16px;
  padding: 10px 20px;
  margin: 0 0 20px;
  border-left: 5px solid #579835; }

.theme-achima blockquote {
  font-family: "lato-regular, sans-serif";
  font-weight: 400;
  color: #000;
  font-size: 16px;
  padding: 10px 20px;
  margin: 0 0 20px;
  border-left: 5px solid #AA2B23; }

.theme-blekinge blockquote {
  font-family: "open-sans-regular, sans-serif";
  font-weight: 400;
  color: #000;
  font-size: 16px;
  padding: 10px 20px;
  margin: 0 0 20px;
  border-left: 5px solid #E5E5E5; }

.theme-sll blockquote {
  font-family: "open-sans-regular, sans-serif";
  font-weight: 400;
  color: #000;
  font-size: 16px;
  padding: 10px 20px;
  margin: 0 0 20px;
  border-left: 5px solid #DAD5D0; }

.theme-halland blockquote {
  font-family: "open-sans-regular, sans-serif";
  font-weight: 400;
  color: #000;
  font-size: 16px;
  padding: 10px 20px;
  margin: 0 0 20px;
  border-left: 5px solid #6DACDE; }

.theme-orebro blockquote {
  font-family: "arial-regular, sans-serif";
  font-weight: 400;
  color: #000;
  font-size: 16px;
  padding: 10px 20px;
  margin: 0 0 20px;
  border-left: 5px solid #EEEEEE; }

.theme-rg blockquote {
  font-family: "museo-sans, sans-serif";
  font-weight: 500;
  color: #000;
  font-size: 16px;
  padding: 10px 20px;
  margin: 0 0 20px;
  border-left: 5px solid #ccc; }

.theme-default .emergencyHeader {
  color: #c81e1e; }

.theme-helsa .emergencyHeader {
  color: #d06b60; }

.theme-ptj .emergencyHeader {
  color: #d92a3f; }

.theme-rjl .emergencyHeader {
  color: #b1063a; }

.theme-achima .emergencyHeader {
  color: #880000; }

.theme-blekinge .emergencyHeader {
  color: #AC0366; }

.theme-sll .emergencyHeader {
  color: #9a0932; }

.theme-halland .emergencyHeader {
  color: #7c0000; }

.theme-orebro .emergencyHeader {
  color: #c81e1e; }

.theme-rg .emergencyHeader {
  color: #f588b2; }

.theme-default .dangerText {
  color: #E32424; }

.theme-helsa .dangerText {
  color: #F48174; }

.theme-ptj .dangerText {
  color: #F54359; }

.theme-rjl .dangerText {
  color: #ce1141; }

.theme-achima .dangerText {
  color: #AA2B23; }

.theme-blekinge .dangerText {
  color: #d70480; }

.theme-sll .dangerText {
  color: #9a0932; }

.theme-halland .dangerText {
  color: #8E0000; }

.theme-orebro .dangerText {
  color: #E32424; }

.theme-rg .dangerText {
  color: #ee3780; }

.theme-default .text-success {
  color: #49b63e; }

.theme-helsa .text-success {
  color: #5b9c6c; }

.theme-ptj .text-success {
  color: #a3aa17; }

.theme-rjl .text-success {
  color: #4c8b2b; }

.theme-achima .text-success {
  color: #009894; }

.theme-blekinge .text-success {
  color: #206C3A; }

.theme-sll .text-success {
  color: #406618; }

.theme-halland .text-success {
  color: #046E15; }

.theme-orebro .text-success {
  color: #76B82A; }

.theme-rg .text-success {
  color: #9ACF8A; }

.theme-default .bodyText {
  color: #000; }

.theme-helsa .bodyText {
  color: #000000; }

.theme-ptj .bodyText {
  color: #ffffff; }

.theme-rjl .bodyText {
  color: #fff; }

.theme-achima .bodyText {
  color: #000; }

.theme-blekinge .bodyText {
  color: #000; }

.theme-sll .bodyText {
  color: #ffffff; }

.theme-halland .bodyText {
  color: #000; }

.theme-orebro .bodyText {
  color: #ffffff; }

.theme-rg .bodyText {
  color: #000; }

.hiddenInfo{
  color: #ffffff;
  text-align: right;
} 
/*----------------------------  Text alignment -------------------------*/
.textCenter {
  text-align: center; }

.textLeft {
  text-align: left !important; }

.textRight {
  text-align: right !important; }

/*--------------------------------  Label ------------------------------*/
label.question {
  font-weight: normal; }

label.question:hover {
  cursor: pointer; }

#journalnoteId label {
  margin-top: 5px;
  margin-bottom: 0; }

/*------------------------  Validation / Alert Text ----------------------*/
.validation-summary-errors {
  font-weight: bold; }
  .theme-default .validation-summary-errors {
    color: #E32424; }
  .theme-helsa .validation-summary-errors {
    color: #F48174; }
  .theme-ptj .validation-summary-errors {
    color: #F54359; }
  .theme-rjl .validation-summary-errors {
    color: #ce1141; }
  .theme-achima .validation-summary-errors {
    color: #AA2B23; }
  .theme-blekinge .validation-summary-errors {
    color: #d70480; }
  .theme-sll .validation-summary-errors {
    color: #9a0932; }
  .theme-halland .validation-summary-errors {
    color: #8E0000; }
  .theme-orebro .validation-summary-errors {
    color: #E32424; }
  .theme-rg .validation-summary-errors {
    color: #ee3780; }

.validation-summary-valid {
  display: none; }

.alert-text {
  font-weight: bold; }
  .theme-default .alert-text {
    color: #E32424; }
  .theme-helsa .alert-text {
    color: #F48174; }
  .theme-ptj .alert-text {
    color: #F54359; }
  .theme-rjl .alert-text {
    color: #ce1141; }
  .theme-achima .alert-text {
    color: #AA2B23; }
  .theme-blekinge .alert-text {
    color: #d70480; }
  .theme-sll .alert-text {
    color: #9a0932; }
  .theme-halland .alert-text {
    color: #8E0000; }
  .theme-orebro .alert-text {
    color: #E32424; }
  .theme-rg .alert-text {
    color: #ee3780; }

/*--------------------------  Bullet list (* * * )  ------------------------*/
ul.arrowBullets {
  list-style: none;
  margin-top: -30px; }

ul.arrowBullets li {
  margin: 1.5rem 0;
  position: relative; }

ul.arrowBullets li:before {
  content: "";
  background-size: contain;
  background-repeat: no-repeat;
  width: 1em;
  height: 1em;
  position: absolute;
  left: -2rem;
  margin-top: 0.1em; }
  .theme-default ul.arrowBullets li:before {
    background-image: url("data:image/svg+xml;utf8,<svg id='Layer_1' data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 51.61 84.46'><defs><style>.cla-1{fill:rgb(10, 74, 124);}</style></defs><title>arrow-right</title><path class='cla-1' d='M7087.7,1576.37l-26.21,26.21a9.38,9.38,0,0,0,13.27,13.27L7107.6,1583a9.37,9.37,0,0,0,0-13.27l-32.84-32.85a9.38,9.38,0,0,0-13.27,13.27Z' transform='translate(-7058.74 -1534.14)'/></svg>"); }
  .theme-helsa ul.arrowBullets li:before {
    background-image: url("data:image/svg+xml;utf8,<svg id='Layer_1' data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 51.61 84.46'><defs><style>.cla-1{fill:rgb(29, 94, 116);}</style></defs><title>arrow-right</title><path class='cla-1' d='M7087.7,1576.37l-26.21,26.21a9.38,9.38,0,0,0,13.27,13.27L7107.6,1583a9.37,9.37,0,0,0,0-13.27l-32.84-32.85a9.38,9.38,0,0,0-13.27,13.27Z' transform='translate(-7058.74 -1534.14)'/></svg>"); }
  .theme-ptj ul.arrowBullets li:before {
    background-image: url("data:image/svg+xml;utf8,<svg id='Layer_1' data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 51.61 84.46'><defs><style>.cla-1{fill:rgb(0, 160, 200);}</style></defs><title>arrow-right</title><path class='cla-1' d='M7087.7,1576.37l-26.21,26.21a9.38,9.38,0,0,0,13.27,13.27L7107.6,1583a9.37,9.37,0,0,0,0-13.27l-32.84-32.85a9.38,9.38,0,0,0-13.27,13.27Z' transform='translate(-7058.74 -1534.14)'/></svg>"); }
  .theme-rjl ul.arrowBullets li:before {
    background-image: url("data:image/svg+xml;utf8,<svg id='Layer_1' data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 51.61 84.46'><defs><style>.cla-1{fill:rgb(87, 152, 53);}</style></defs><title>arrow-right</title><path class='cla-1' d='M7087.7,1576.37l-26.21,26.21a9.38,9.38,0,0,0,13.27,13.27L7107.6,1583a9.37,9.37,0,0,0,0-13.27l-32.84-32.85a9.38,9.38,0,0,0-13.27,13.27Z' transform='translate(-7058.74 -1534.14)'/></svg>"); }
  .theme-achima ul.arrowBullets li:before {
    background-image: url("data:image/svg+xml;utf8,<svg id='Layer_1' data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 51.61 84.46'><defs><style>.cla-1{fill:rgb(136, 0, 0);}</style></defs><title>arrow-right</title><path class='cla-1' d='M7087.7,1576.37l-26.21,26.21a9.38,9.38,0,0,0,13.27,13.27L7107.6,1583a9.37,9.37,0,0,0,0-13.27l-32.84-32.85a9.38,9.38,0,0,0-13.27,13.27Z' transform='translate(-7058.74 -1534.14)'/></svg>"); }
  .theme-blekinge ul.arrowBullets li:before {
    background-image: url("data:image/svg+xml;utf8,<svg id='Layer_1' data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 51.61 84.46'><defs><style>.cla-1{fill:rgb(0, 166, 226);}</style></defs><title>arrow-right</title><path class='cla-1' d='M7087.7,1576.37l-26.21,26.21a9.38,9.38,0,0,0,13.27,13.27L7107.6,1583a9.37,9.37,0,0,0,0-13.27l-32.84-32.85a9.38,9.38,0,0,0-13.27,13.27Z' transform='translate(-7058.74 -1534.14)'/></svg>"); }
  .theme-sll ul.arrowBullets li:before {
    background-image: url("data:image/svg+xml;utf8,<svg id='Layer_1' data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 51.61 84.46'><defs><style>.cla-1{fill:rgb(0, 45, 90);}</style></defs><title>arrow-right</title><path class='cla-1' d='M7087.7,1576.37l-26.21,26.21a9.38,9.38,0,0,0,13.27,13.27L7107.6,1583a9.37,9.37,0,0,0,0-13.27l-32.84-32.85a9.38,9.38,0,0,0-13.27,13.27Z' transform='translate(-7058.74 -1534.14)'/></svg>"); }
  .theme-halland ul.arrowBullets li:before {
    background-image: url("data:image/svg+xml;utf8,<svg id='Layer_1' data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 51.61 84.46'><defs><style>.cla-1{fill:rgb(109, 172, 222);}</style></defs><title>arrow-right</title><path class='cla-1' d='M7087.7,1576.37l-26.21,26.21a9.38,9.38,0,0,0,13.27,13.27L7107.6,1583a9.37,9.37,0,0,0,0-13.27l-32.84-32.85a9.38,9.38,0,0,0-13.27,13.27Z' transform='translate(-7058.74 -1534.14)'/></svg>"); }
  .theme-orebro ul.arrowBullets li:before {
    background-image: url("data:image/svg+xml;utf8,<svg id='Layer_1' data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 51.61 84.46'><defs><style>.cla-1{fill:rgb(79, 128, 255);}</style></defs><title>arrow-right</title><path class='cla-1' d='M7087.7,1576.37l-26.21,26.21a9.38,9.38,0,0,0,13.27,13.27L7107.6,1583a9.37,9.37,0,0,0,0-13.27l-32.84-32.85a9.38,9.38,0,0,0-13.27,13.27Z' transform='translate(-7058.74 -1534.14)'/></svg>"); }
  .theme-rg ul.arrowBullets li:before {
    background-image: url("data:image/svg+xml;utf8,<svg id='Layer_1' data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 51.61 84.46'><defs><style>.cla-1{fill:rgb(0, 151, 207);}</style></defs><title>arrow-right</title><path class='cla-1' d='M7087.7,1576.37l-26.21,26.21a9.38,9.38,0,0,0,13.27,13.27L7107.6,1583a9.37,9.37,0,0,0,0-13.27l-32.84-32.85a9.38,9.38,0,0,0-13.27,13.27Z' transform='translate(-7058.74 -1534.14)'/></svg>"); }

.validation-summary-errors ul {
  list-style: none;
  margin-left: -40px; }

/*---------------------  Number list (1, 2, 3 ... etc.)  -------------------*/
ol {
  margin: 0 0 1.5em;
  padding: 0.5em;
  counter-reset: item; }

ol li::after before {
  height: 18px;
  width: 18px; }

ol > li {
  margin: 0 0 16px 0;
  padding: 0 0 0 3em;
  text-indent: -2.6em;
  list-style-type: none;
  counter-increment: item; }

ol > li:before {
  content: counter(item) "";
  margin-right: 0.8em;
  padding: 5px 13px;
  border-radius: 100%; }
  .theme-default ol > li:before {
    background-color: #0A4A7C;
    color: #A0F0EB;
    font-weight: 700; }
  .theme-helsa ol > li:before {
    background-color: #1D5E74;
    color: #EBF5EC;
    font-weight: 700; }
  .theme-ptj ol > li:before {
    background-color: #00A0C8;
    color: #ffffff;
    font-weight: 700; }
  .theme-rjl ol > li:before {
    background-color: #579835;
    color: #fff;
    font-weight: 600; }
  .theme-achima ol > li:before {
    background-color: #880000;
    color: #fff;
    font-weight: 700; }
  .theme-blekinge ol > li:before {
    background-color: #00A6E2;
    color: #fff;
    font-weight: 700; }
  .theme-sll ol > li:before {
    background-color: #002d5a;
    color: #fff;
    font-weight: 700; }
  .theme-halland ol > li:before {
    background-color: #6DACDE;
    color: #fff;
    font-weight: 700; }
  .theme-orebro ol > li:before {
    background-color: #4F80FF;
    color: #fff;
    font-weight: 700; }
  .theme-rg ol > li:before {
    background-color: #0097cf;
    color: #fff;
    font-weight: 700; }

ol > li:last-child {
  margin-bottom: 0; }

/*---------------------------------------------------------------------------
                               FONT AWESOME STYLING 
---------------------------------------------------------------------------*/
.fa-margin {
  margin-right: 8px; }

/*---------------------------------------------------------------------------
                               SPECIAL FONT STYLING 
---------------------------------------------------------------------------*/
.theme-default .boldText, .theme-default p.boldText, .theme-default a.boldText, .theme-default span.boldText, .theme-default label.boldText {
  font-weight: bold; }

.theme-helsa .boldText, .theme-helsa p.boldText, .theme-helsa a.boldText, .theme-helsa span.boldText, .theme-helsa label.boldText {
  font-weight: bold; }

.theme-ptj .boldText, .theme-ptj p.boldText, .theme-ptj a.boldText, .theme-ptj span.boldText, .theme-ptj label.boldText {
  font-weight: bold; }

.theme-rjl .boldText, .theme-rjl p.boldText, .theme-rjl a.boldText, .theme-rjl span.boldText, .theme-rjl label.boldText {
  font-weight: bold; }

.theme-achima .boldText, .theme-achima p.boldText, .theme-achima a.boldText, .theme-achima span.boldText, .theme-achima label.boldText {
  font-weight: bold; }

.theme-blekinge .boldText, .theme-blekinge p.boldText, .theme-blekinge a.boldText, .theme-blekinge span.boldText, .theme-blekinge label.boldText {
  font-weight: bold; }

.theme-sll .boldText, .theme-sll p.boldText, .theme-sll a.boldText, .theme-sll span.boldText, .theme-sll label.boldText {
  font-weight: bold; }

.theme-halland .boldText, .theme-halland p.boldText, .theme-halland a.boldText, .theme-halland span.boldText, .theme-halland label.boldText {
  font-weight: bold; }

.theme-orebro .boldText, .theme-orebro p.boldText, .theme-orebro a.boldText, .theme-orebro span.boldText, .theme-orebro label.boldText {
  font-weight: bold; }

.theme-rg .boldText, .theme-rg p.boldText, .theme-rg a.boldText, .theme-rg span.boldText, .theme-rg label.boldText {
  font-weight: bold; }

.sub-headline {
  display: block;
  text-align: center;
  font-size: 14px;
  margin-bottom: 0; }

.languageP {
  margin-left: 12px; }

.journalTypeButtons .panel-heading {
  text-align: center; }

.journalMenuBody .form-horizontal .form-group label {
  font-size: 14px;
  padding: 0px; }

.text-with-line-break {
  white-space: pre-line; }

/*-------------------------  Symptom form questions ------------------------*/
.admin .question-block .question {
  font-size: 14px; }

.unitSpan {
  margin-top: 6px;
  margin-left: 6px;
  margin-right: 6px;
  float: right;
  font-weight: normal; }

.unitLabel .unitSpan {
  font-size: 14px; }

.admin .groupTitle {
  font-size: 16px;
  font-weight: bold;
  text-align: left; }

/*---------------------------  Patient Registration -----------------------*/
.welcome-title {
  text-align: left;
  font-weight: 600; }
  .theme-default .welcome-title {
    color: #0A4A7C; }
  .theme-helsa .welcome-title {
    color: #1D5E74; }
  .theme-ptj .welcome-title {
    color: #333333; }
  .theme-rjl .welcome-title {
    color: #000; }
  .theme-achima .welcome-title {
    color: #880000; }
  .theme-blekinge .welcome-title {
    color: #000; }
  .theme-sll .welcome-title {
    color: #000; }
  .theme-halland .welcome-title {
    color: #000; }
  .theme-orebro .welcome-title {
    color: #000; }
  .theme-rg .welcome-title {
    color: #000; }

.step {
  text-align: right; }
  .theme-default .step {
    color: #0A4A7C; }
  .theme-helsa .step {
    color: #1D5E74; }
  .theme-ptj .step {
    color: #333333; }
  .theme-rjl .step {
    color: #000; }
  .theme-achima .step {
    color: #880000; }
  .theme-blekinge .step {
    color: #000; }
  .theme-sll .step {
    color: #000; }
  .theme-halland .step {
    color: #000; }
  .theme-orebro .step {
    color: #000; }
  .theme-rg .step {
    color: #000; }

.form-step {
  text-align: center; }

.welcome-info {
  font-size: 16px; }

.continue-arrow {
  font-size: 52px; }
  .theme-default .continue-arrow {
    color: #0A4A7C; }
  .theme-helsa .continue-arrow {
    color: #1D5E74; }
  .theme-ptj .continue-arrow {
    color: #0075B0; }
  .theme-rjl .continue-arrow {
    color: #579835; }
  .theme-achima .continue-arrow {
    color: #AA2B23; }
  .theme-blekinge .continue-arrow {
    color: #00A6E2; }
  .theme-sll .continue-arrow {
    color: #002d5a; }
  .theme-halland .continue-arrow {
    color: #7BB1DB; }
  .theme-orebro .continue-arrow {
    color: #4F80FF; }
  .theme-rg .continue-arrow {
    color: #0097cf; }

/* Bigger than XS sqreen size (col-md-up) */
@media screen and (min-width: 768px) {
  .welcome-title {
    font-size: 30px; }
  .step {
    font-size: 22px; }
  .registration label {
    font-size: 22px; }
  .registration p, .registration ul.arrowBullets li, .registration ol > li {
    font-size: 22px; }
  .bigBulletArrow {
    height: 28px;
    margin-top: -6px;
    margin-left: 40px;
    margin-right: 8px; }
    .theme-default .bigBulletArrow {
      opacity: 1; }
    .theme-helsa .bigBulletArrow {
      opacity: 1; }
    .theme-ptj .bigBulletArrow {
      opacity: 1; }
    .theme-rjl .bigBulletArrow {
      opacity: 1; }
    .theme-achima .bigBulletArrow {
      opacity: 1; }
    .theme-blekinge .bigBulletArrow {
      opacity: 1; }
    .theme-sll .bigBulletArrow {
      opacity: 1; }
    .theme-halland .bigBulletArrow {
      opacity: 1; }
    .theme-orebro .bigBulletArrow {
      opacity: 1; }
    .theme-rg .bigBulletArrow {
      opacity: 1; }
  .inbetweenBullets {
    margin-top: 20px;
    margin-bottom: 20px; }
  .afterBullets {
    display: inline;
    margin-left: 72px; } }

/* Smaler than XS sqreen size */
@media screen and (max-width: 768px) {
  .welcome-title {
    font-size: 24px; }
  .step {
    font-size: 18px; }
  .bigBulletArrow {
    height: 20px;
    margin-left: 20px;
    margin-right: 6px;
    margin-top: -2px; }
    .theme-default .bigBulletArrow {
      opacity: 1; }
    .theme-helsa .bigBulletArrow {
      opacity: 1; }
    .theme-ptj .bigBulletArrow {
      opacity: 1; }
    .theme-rjl .bigBulletArrow {
      opacity: 1; }
    .theme-achima .bigBulletArrow {
      opacity: 1; }
    .theme-blekinge .bigBulletArrow {
      opacity: 1; }
    .theme-sll .bigBulletArrow {
      opacity: 1; }
    .theme-halland .bigBulletArrow {
      opacity: 1; }
    .theme-orebro .bigBulletArrow {
      opacity: 1; }
    .theme-rg .bigBulletArrow {
      opacity: 1; }
  .registration.patientInformation {
    margin-top: -100px; } }

.patientRegistration .groupTitle {
  margin-top: 10px; }

.theme-default .admin label {
  color: #000; }

.theme-helsa .admin label {
  color: #000000; }

.theme-ptj .admin label {
  color: #333333; }

.theme-rjl .admin label {
  color: #000; }

.theme-achima .admin label {
  color: #000; }

.theme-blekinge .admin label {
  color: #000; }

.theme-sll .admin label {
  color: #000000; }

.theme-halland .admin label {
  color: #000000; }

.theme-orebro .admin label {
  color: #000000; }

.theme-rg .admin label {
  color: #000000; }

.registration label {
  width: auto;
  display: inline-block;
  margin-left: 36px; }

.patientanswer label {
  width: auto;
  display: inline-block;
  min-width: calc(50% - 20px); }

.maxDaysModal {
  padding-right: 50px;
  padding-left: 75px;
  padding-bottom: 20px; }

.three-toggle label {
  width: auto;
  display: inline-block;
  min-width: calc(33% - 20px); }

@media screen and (min-width: 768px) {
  p.telephoneText {
    font-size: 22px; }
    .theme-default p.telephoneText {
      color: #000000; }
    .theme-helsa p.telephoneText {
      color: #000000; }
    .theme-ptj p.telephoneText {
      color: #333333; }
    .theme-rjl p.telephoneText {
      color: #000; }
    .theme-achima p.telephoneText {
      color: #000; }
    .theme-blekinge p.telephoneText {
      color: #000; }
    .theme-sll p.telephoneText {
      color: #000; }
    .theme-halland p.telephoneText {
      color: #000; }
    .theme-orebro p.telephoneText {
      color: #000; }
    .theme-rg p.telephoneText {
      color: #000; } }

@media screen and (max-width: 768px) {
  p.telephoneText {
    font-size: 14px; }
    .theme-default p.telephoneText {
      color: #000000; }
    .theme-helsa p.telephoneText {
      color: #000000; }
    .theme-ptj p.telephoneText {
      color: #333333; }
    .theme-rjl p.telephoneText {
      color: #000; }
    .theme-achima p.telephoneText {
      color: #000; }
    .theme-blekinge p.telephoneText {
      color: #000; }
    .theme-sll p.telephoneText {
      color: #000; }
    .theme-halland p.telephoneText {
      color: #000; }
    .theme-orebro p.telephoneText {
      color: #000; }
    .theme-rg p.telephoneText {
      color: #000; } }

.note-title {
  font-size: 16px;
  font-weight: bold;
  text-align: left; }

.note-title2 {
  font-size: 14px;
  font-weight: bold;
  text-align: left; }

/*--------------------------- ? MAYBE NOT NEEDED ? -----------------------*/
/* Don't think we use .panel-heading */
.panel-heading h4.panel-title {
  font-weight: bold; }
  .theme-default .panel-heading h4.panel-title {
    color: #0A4A7C; }
  .theme-helsa .panel-heading h4.panel-title {
    color: #1D5E74; }
  .theme-ptj .panel-heading h4.panel-title {
    color: #FFFFFF; }
  .theme-rjl .panel-heading h4.panel-title {
    color: #000; }
  .theme-achima .panel-heading h4.panel-title {
    color: #880000; }
  .theme-blekinge .panel-heading h4.panel-title {
    color: #000; }
  .theme-sll .panel-heading h4.panel-title {
    color: #000000; }
  .theme-halland .panel-heading h4.panel-title {
    color: #000000; }
  .theme-orebro .panel-heading h4.panel-title {
    color: #000000; }
  .theme-rg .panel-heading h4.panel-title {
    color: #000000; }

.panel-heading p.panel-title {
  font-weight: 600;
  font-size: 16px; }
  .theme-default .panel-heading p.panel-title {
    color: #0A4A7C; }
  .theme-helsa .panel-heading p.panel-title {
    color: #1D5E74; }
  .theme-ptj .panel-heading p.panel-title {
    color: #FFFFFF; }
  .theme-rjl .panel-heading p.panel-title {
    color: #000; }
  .theme-achima .panel-heading p.panel-title {
    color: #880000; }
  .theme-blekinge .panel-heading p.panel-title {
    color: #000; }
  .theme-sll .panel-heading p.panel-title {
    color: #000000; }
  .theme-halland .panel-heading p.panel-title {
    color: #000000; }
  .theme-orebro .panel-heading p.panel-title {
    color: #000000; }
  .theme-rg .panel-heading p.panel-title {
    color: #000000; }

.panel-heading p.panel-title i {
  font-size: 14px; }

.panel-heading p.panel-title i.fa-chevron-down {
  font-size: 14px;
  position: relative;
  bottom: 1px; }

.panel-heading {
  cursor: pointer; }

.panel-heading h4.panel-title a:after {
  font-family: 'Glyphicons Halflings';
  content: "\e114";
  float: right; }
  .theme-default .panel-heading h4.panel-title a:after {
    color: #0A4A7C; }
  .theme-helsa .panel-heading h4.panel-title a:after {
    color: #1D5E74; }
  .theme-ptj .panel-heading h4.panel-title a:after {
    color: #FFFFFF; }
  .theme-rjl .panel-heading h4.panel-title a:after {
    color: #000; }
  .theme-achima .panel-heading h4.panel-title a:after {
    color: #880000; }
  .theme-blekinge .panel-heading h4.panel-title a:after {
    color: #000; }
  .theme-sll .panel-heading h4.panel-title a:after {
    color: #000000; }
  .theme-halland .panel-heading h4.panel-title a:after {
    color: #000000; }
  .theme-orebro .panel-heading h4.panel-title a:after {
    color: #000000; }
  .theme-rg .panel-heading h4.panel-title a:after {
    color: #000000; }

.panel-heading h4.panel-title a.collapsed:after {
  content: "\e080"; }

/*-----------------------------------------------------------------------*/
