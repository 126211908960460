@import 'themes.scss';
@import 'mixins.scss';

$card-radius: 15px;
$textSection-radius: 10px;
$textarea-radius: 8px;

/*-----------------------------  Body ----------------------------*/
.dark {
    @include themify ($themes) {
        background-color: themed('light-color-supplement');
        margin-bottom: -30px;
        padding-bottom: 30px;
    }
}

body .patientcontact .admin {
    @include themify ($themes) {
        font-size: 16px;
    }
}

/*--------------------  Navbar / Second header --------------------*/
.secondHeader {
    @include themify($themes) {
        background-color: themed('light-color');
        color: themed('primary-paragraph-color');
    }
    padding-top: 15px;
    padding-bottom: 5px;
    border: none;
    -webkit-box-shadow: 0 2px 6px -6px #000;
	   -moz-box-shadow: 0 2px 6px -6px #000;
	        box-shadow: 0 2px 6px -6px #000;
}


/*-----------------------------  Card ---------------------------*/
.card {
    @include themify($themes) {
        border: none;
        border-radius: $card-radius;
        background-color: themed('light-color');
    }
    -webkit-box-shadow: 0 2px 6px -6px #000;
    -moz-box-shadow: 0 2px 6px -6px #000;
         box-shadow: 0 2px 6px -6px #000;
}

.card.default .card-header, .card.default .card-body, .card.default {
    @include themify($themes) {
        background-color: themed('disabled-color-supplement');
    }
}

.card.default {
    box-shadow: none;
}

.card .card-header{
    @include themify($themes) {
        border-top: none;
        background-color: themed('light-color');
    }
}

.card .card-header h5{
    @include themify($themes) {
        color: themed('primary-paragraph-color');
    }
}

.card-header:first-child {
    border-radius: $card-radius $card-radius 0 0;
}

.card-body{
    border-radius: $card-radius;
}

.card-body label{
    font-weight: bold;
    margin-bottom: 0;
}

.card-body .blackText{
    color: #000;
}

.cardLabel{
    margin: 5px 15px 0px 15px;
}

.card-body h5{
 font-size: 18px;
}

.textSection .blueText{
    color: #0A4A7C;
}

.navMenu
{
    color:  #f3f3f3;
}
/*---------------------------  Textarea --------------------------*/

.DayPickerInput input, .numberInput input{
    width: 100%;
    max-width: 190px;
}

.patientcontact input[type=search]{
    @include themify($themes) {
        border-color: themed('textbox-border-color');
        border-radius: themed('btn-radius');
        padding-left: 15px;
    }
}

// @media screen and (max-width: 992px) {
//     .DayPickerInput input, .numberInput input{
//     width: 100%;
//         max-width: 190px;
//     }
// }

.patientcontact .admin .question-block
{
    @include themify($themes) {
        padding: .375rem .75rem;
        border-radius: $textarea-radius;
    }
}

.patientcontact .admin .question-block div
{
    padding-left: 0;
    padding-right: 0;
}

.patientcontact .admin textarea.highlight {
    @include themify($themes) {
        background-color: themed('symptom-btn-background-color');
        color: themed('symptom-btn-text-color');
    }
}

.patientcontact .admin textarea.highlight:focus {
    @include themify($themes) {
        background-color: themed('symptom-btn-background-color');
        color: themed('symptom-btn-text-color');
    }
}

.asterisk{
    @include themify($themes) {
        color: themed('danger-color');
        font-weight: bold;
        margin-right: 5px;
    }
}

.patientcontact .admin textarea.straightRightTopCorner{
    border-top-right-radius: 0;
}

.patientcontact .admin .btn.copy-btn{
    padding: .375rem .75rem;
    font-size: 16px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    margin-bottom: -2px;
    margin-right: 0;
}

 .patientcontact .admin .diagnoseRow .btn.copy-btn{
    padding: .375rem .75rem;
    font-size: 16px;
border-radius: 50px;
    margin-bottom: -2px;
    margin-right: 0;
    float: right;
}

.admin .diagnosCopyDiv{
    padding-left: 0;
    padding-right: 0;
    margin-left: -15px;
}

.patientcontact .admin .diagnoseRow .diagnoseSection{
    padding: 2px 8px;
}

.card .card-body .textSection{
    @include themify($themes) {
        background-color: themed('light-color-supplement');
        border: 1px solid themed('primary-color');
    }
    border-radius: $textSection-radius;
    border-top-right-radius: 0;
    padding: 15px;
    margin: 0 15px;
}

.hidden{
    display: none;
}

.paddingLeft20 {
    padding-left: 20px;
}


.preWrapText{
    white-space: pre-line;
}

/*---------------------------  Textarea --------------------------*/
ul.pagination a.page-link{
    min-width: 38px;
}


/*----------------------------  Navbar ---------------------------*/

.navbar .container{
    padding-left: 15px;
    padding-right: 15px;
}

.navbar-toggler{
    padding: 0;
}

.nav-link{
    font-size: 16px;
}

@media screen and (min-width: 992px) {
    .nav-item .nav-link{
        margin-top: -16px;
        padding-top: 16px;
        margin-bottom: -16px;
        padding-bottom: 16px;
    }
}

@media screen and (max-width: 992px) {
    .navbar li a{
        @include themify($themes) {
            color: themed('navbar-text-color') !important;
            background-color: themed('navbar-bg-color');
        }
    }
    .navbar li a:hover{
        @include themify($themes) {
            color: themed('navbar-text-bg-color-hover');
        }
    }
}

.navbar-toggler span.fa{
    @include themify($themes) {
        color: themed('navbar-text-color');
    }
}

/*----------------------------  Lists ---------------------------*/

.list {
    list-style: none;
  }

  .list .listItem::before {
    @include themify($themes) {
        color: themed('primary-color');
    }
    content: "\2022";
    font-weight: bold;
    display: inline-block;
    width: 1em;
    margin-left: -1em;
  }

  .issueItem:hover{
    cursor: pointer;
    @include themify($themes){
        background-color: themed('symptom-btn-icon-color-hover');
    }
}

  /*----------------------------  Fonts ---------------------------*/

  .consentLabel{
    margin-left: 5px;
    font-weight: bold;
  }

  .answerTextBox{
      overflow: hidden;
  }

  .smallText{
    float: right;
    font-size: 12px;
  }

  .validationText{
    @include themify($themes) {
        color: themed('primary-color');
  }
}

  /*----------------------------  Forms ---------------------------*/
  .well .input-one-line {
    @include themify($themes){
        background-color: themed('light-color');
        border-color: themed('secondary-color');
        margin-bottom: 0.5em;
    }
  }

  .well .small-textarea {
    @include themify($themes){
        width: 100%;
        background-color: themed('light-color');
        border-color: themed('secondary-color');
        margin-bottom: 0.5em;
    }
}

@media screen and (max-width: 768px) {
    .well{
        margin-bottom: 15px;
        margin-top: 15px;
    }
}

  /*----------------------------  Other ---------------------------*/

.marginTop50{
    margin-top: 50px;
  }

  .rightMargin-md{
    margin-right: 10px;
}

.topMargin-xs{
    margin-top: 3px;
}

.information-icon{
 width: 64px;
 float: right;
 margin-top: 5px;
}

    /*----------------------------  Button ---------------------------*/

.linkBtn, .linkBtn:not(:disabled):focus {
        @include themify($themes){
            color: themed('primary-color');
            background-color: themed('textbox-bg-color');
        }
        border: none;
        padding: 0;
        outline: none;
      }

.linkBtnDanger{
        @include themify($themes){
            color: themed('danger-color');
            font-weight: bold;
            border: none;
            background: none;
            padding: 0;
            text-decoration: underline;
        }
      }


    /*----------------------------  Chat ---------------------------*/

.currentSender{
    @include themify($themes){
        background-color:  themed('symptom-btn-border-color');
        color: black;
        padding: 10px;
        font-size: 15px;
        margin-right: 10px;
        white-space: pre-line;
        overflow-wrap: break-word;
    }
}

.currentReceiver{
    background-color: #fff;
    color: black;
    padding: 10px;
    margin-left: 10px;
    font-size: 15px;
    white-space: pre-line;
    overflow-wrap: break-word;
    border: 1px solid #e4e4e4;
}

.messageTextBox{
    @include themify($themes){
    background-color:white;
    }
}

.messageTextBox:focus{
    @include themify($themes){
    background-color:white;
    border-color: themed('primary-color');
    }
}

.chatContainer{
        height: 350px;
        overflow-y: auto;
        overflow-x: hidden;
        margin-left:0px;
        margin-right: 0px;
}

.well.chatWell{
    @include themify($themes){
    background-color: themed('light-color-supplement');
    padding:0px 0px 0px 5px;
    border:1px solid themed('light-color-supplement');
    }
}

.chatHistoryContainer{
  display: flex;
  flex-direction: column;
}

.chatHistory{
  @include themify($themes){
    background-color: themed('light-color-supplement');
    padding:0px 0px 0px 5px;
    border:1px solid themed('light-color-supplement');
    flex-grow: 1;
    overflow-y: scroll;
    height: 490px;
  }
}

.noMeetingMessage{
  text-align: center;
  padding-top: 100px;
}

.paddingRight10{
    padding-right: 10px;
}

.attachment-section{
  @include themify($themes) {
    background-color: themed('question-block-bg-color');
    border: 0px solid themed('primary-color');
    padding: 4px;
    margin-bottom: 8px;
    box-shadow: rgba(0, 0, 0, 0.05) 2.4px 2.4px 3.2px;
  }
}

.attachment-text{
    text-align: left;
    width: 95%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.loading{
    opacity: 0.5;
}


        /*---------------------------- react-select SelectList ---------------------------*/

        .select__control.select__control--is-focused{
            @include themify($themes){
                border-color: themed('primary-color');
                box-shadow: none;
                }
        }

        .select__option.select__option--is-focused{
            @include themify($themes){
                background-color: themed('symptom-btn-background-color');
                color: black
                }
        }

        .select__option.select__option--is-selected{
            @include themify($themes){
                background-color: themed('symptom-btn-border-color');
                color: black
                }
        }
        .select__option--is-focused.select__option--is-selected{
            @include themify($themes){
                background-color: themed('symptom-btn-border-color');
                color: black
                }
        }
